<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import tokenUtils from "../utils/tokenUtils";

export default {
  name: "Base",
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
    this.verifiationToken()
    this.$store.commit('initData')
  },
  methods: {
    /**
     * 토큰 검증
     */
    async verifiationToken() {
      await tokenUtils.verification().then(() => {
        this.trackLogin()
      }).catch(error => {
        alert(error)
        this.$router.push({ name: 'login' })
      })
    },
    trackLogin () {
      const parameterUser = {'employeeNumber': sessionStorage.getItem('userId')}
      window.gtag('event', 'Page_login', parameterUser)
    },
  },
}
</script>
